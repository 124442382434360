import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import B1 from "../images/B1.svg";
import B2 from "../images/B2.svg";
import B3 from "../images/B3.svg";
import B4 from "../images/B4.svg";
import B5 from "../images/B5.svg";
import { Link } from "react-router-dom";
const Benefits = () => {
  useEffect(() => {
    AOS.init({ once: false });
  }, []);

  return (
    <section className="w-full flex justify-center items-center px-8 md:px-24 py-24">
      <div className="flex flex-col space-y-6">
        <div
          className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-6"
          data-aos="fade-up"
        >
          <div className="flex flex-col items-start justify-center space-y-4 md:w-[33%]">
            <h1 className="text-primary font-jakarta font-extrabold text-[40px]">
              Key Benefits:
            </h1>
            <p className="text-[#52525C] font-outfit font-normal text-2xl">
              Our 2.5D virtual exhibition platform is here to revolutionize
              the way you connect with potential buyers.
            </p>
          </div>
          <div
            className="bg-white p-10 rounded-2xl shadow-gray-300 shadow-xl space-y-5 md:w-[33%]"
            data-aos="fade-right"
          >
            <img src={B1} alt="benefit1" />
            <h1 className="text-[#151718] font-poppins font-semibold text-2xl">
              Seamless Connections
            </h1>
            <p className="text-[#52525C] font-outfit font-normal text-[20px]">
              Connect with potential buyers from the Asia-Pacific region in a
              virtual space.
            </p>
          </div>
          <div
            className="bg-white p-10 rounded-2xl shadow-gray-300 shadow-xl space-y-5 md:w-[33%]"
            data-aos="fade-right"
          >
            <img src={B2} alt="benefit2" />
            <h1 className="text-[#151718] font-poppins font-semibold text-2xl">
              Immersive Experience
            </h1>
            <p className="text-[#52525C] font-outfit font-normal text-[20px]">
              Explore a visually appealing 2.5D environment that enhances
              engagement.
            </p>
          </div>
        </div>
        <div
          className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-6"
          data-aos="fade-up"
        >
          <div
            className="bg-white p-10 rounded-2xl shadow-gray-300 shadow-xl space-y-5 md:w-[33%]"
            data-aos="fade-right"
          >
            <img src={B3} alt="benefit3" />
            <h1 className="text-[#151718] font-poppins font-semibold text-2xl">
              Efficient Business Matching
            </h1>
            <p className="text-[#52525C] font-outfit font-normal text-[20px]">
              Find and connect with relevant buyers using advanced matching
              tools.
            </p>
          </div>
          <div
            className="bg-white p-10 rounded-2xl shadow-gray-300 shadow-xl space-y-5 md:w-[33%]"
            data-aos="fade-right"
          >
            <img src={B4} alt="benefit4" />
            <h1 className="text-[#151718] font-poppins font-semibold text-2xl">
              Real-Time Communication
            </h1>
            <p className="text-[#52525C] font-outfit font-normal text-[20px]">
              Communicate directly with potential partners through built-in
              tools.
            </p>
          </div>
          <div
            className="bg-white p-10 rounded-2xl shadow-gray-300 shadow-xl space-y-5 md:w-[33%]"
            data-aos="fade-right"
          >
            <img src={B5} alt="benefit5" />
            <h1 className="text-[#151718] font-poppins font-semibold text-2xl">
              Accessibility
            </h1>
            <p className="text-[#52525C] font-outfit font-normal text-[20px]">
              Enjoy a responsive design compatible with various devices and
              browsers.
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center" data-aos="fade-up">
          <Link to="/contact-us">
          <button className="bg-primary text-white mt-14 py-[14px] px-10 rounded-[30px] font-outfit font-semibold text-sm">
            Get Started
          </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
