import React, { useState, useEffect } from "react";
import countdown from "../images/countdown.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
const Countdown = () => {
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 10); // Set target date to 10 days from now

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
      once: false, // Animation triggers every time on scroll
    });
  }, []);

  return (
    <section
      className="text-center text-white relative bg-black bg-opacity-50"
      style={{
        backgroundImage: `url(${countdown})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="rounded-md bg-black bg-opacity-50 p-6">
       <div className="mb-14 space-y-4">
       <p className="text-lg mt-12 sm:text-sm font-semibold mb-6" data-aos="fade-up">
          UPCOMING EXHIBITIONS
        </p>
        <h2
          className="text-2xl lg:text-6xl font-bold  "
          data-aos="fade-up"
        >
          Counting Time & Not Yet Registered? 
        </h2>
        <h2
          className="text-2xl  lg:text-6xl font-bold lg:px-24 mb-3"
          data-aos="fade-up"
        >
           Hurry Up!
        </h2>
       </div>

        <div className="flex flex-wrap justify-center gap-4 sm:space-y-0 sm:space-x-6">
          <div
            className="bg-black bg-opacity-20 backdrop-blur-sm rounded-2xl w-28 h-28 sm:w-60 sm:h-52 flex flex-col justify-center items-center border-2 border-gray-500"
            data-aos="flip-left"
          >
            <h3 className="text-4xl sm:text-5xl font-bold">{timeLeft.days}</h3>
            <p>Days</p>
          </div>
          <div
            className="bg-black bg-opacity-20 backdrop-blur-sm rounded-2xl w-28 h-28 sm:w-60 sm:h-52 flex flex-col justify-center items-center border-2 border-gray-500"
            data-aos="flip-right"
          >
            <h3 className="text-4xl sm:text-5xl font-bold">{timeLeft.hours}</h3>
            <p>Hours</p>
          </div>
          <div
            className="bg-black bg-opacity-20 backdrop-blur-sm rounded-2xl w-28 h-28 sm:w-60 sm:h-52 flex flex-col justify-center items-center border-2 border-gray-500"
            data-aos="flip-up"
          >
            <h3 className="text-4xl sm:text-5xl font-bold">{timeLeft.minutes}</h3>
            <p>Minutes</p>
          </div>
          <div
            className="bg-black bg-opacity-20 backdrop-blur-sm rounded-2xl w-28 h-28 sm:w-60 sm:h-52 flex flex-col justify-center items-center border-2 border-gray-500"
            data-aos="flip-down"
          >
            <h3 className="text-4xl sm:text-5xl font-bold">{timeLeft.seconds}</h3>
            <p>Seconds</p>
          </div>
        </div>

        
        <Link to="/contact-us">
        <button
          className="bg-white text-[#2E2460] rounded-3xl px-6 py-2 mt-12 font-bold mb-16"
          data-aos="zoom-in"
        >
          Register Now +
        </button>
          </Link>
      </div>
    </section>
  );
};

export default Countdown;
