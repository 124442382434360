import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import elipes from "../images/elipses.svg";
import result from "../images/resultBg.svg";

const Results = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: true }); // Initialize AOS with a duration and run-once setting
  }, []);

  return (
    <section className="py-20 text-[#2E2460] relative px-2">
      <div className="text-center mb-3" data-aos="fade-up">
        <p className="text-black text-sm font-bold">OUR IMPACT</p>
      </div>
      <div
        style={{
          backgroundImage: `url(${result})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2
          className="text-3xl md:text-6xl font-bold text-center mb-10 sm:mb-20"
          data-aos="fade-up"
        >
          Our Measurable Results
        </h2>
        <div className="max-w-[80%] mx-auto flex justify-around gap-8 relative z-10">
          <div
            className="text-center relative w-full sm:w-1/3"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <img src={elipes} alt="Participants Served" className="mx-auto mb-4 md:w-[80%]" />
            <div className="font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 text-[#1D1D1D] py-2 rounded bg-transparent bg-opacity-70">
              <h3 className="text-2xl sm:text-5xl font-bold">5M+</h3>
              <p className="text-[6px] sm:text-base text-[#58595B]">Participants served</p>
            </div>
          </div>
          <div
            className="text-center relative w-full sm:w-1/3"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <img src={elipes} alt="Exhibitions Served" className="mx-auto mb-4  md:w-[80%]" />
            <div className="font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 text-[#1D1D1D] py-2 rounded bg-transparent bg-opacity-70">
              <h3 className="text-2xl sm:text-5xl font-bold">5k+</h3>
              <p className="text-[6px]  sm:text-base text-[#58595B]">Exhibitions Served</p>
            </div>
          </div>
          <div
            className="text-center relative w-full sm:w-1/3"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <img src={elipes} alt="Years in Industry" className="mx-auto mb-4 md:w-[80%]" />
            <div className="font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 text-[#1D1D1D] py-2 rounded bg-transparent bg-opacity-70">
              <h3 className="text-2xl sm:text-5xl font-bold">5+</h3>
              <p className="text-[6px]  sm:text-base text-[#58595B]">Years in Industry</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Results;
