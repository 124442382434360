import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable"; // Import swipeable hooks
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import icon from "../images/testemonalIcon.svg";

const testimonials = [
  {
    name: "Sarah Jack",
    role: "VP Product",
    feedback:
      "The 2.5D virtual exhibition platform exceeded our expectations with its interactive, yet easy-to-use interface. Visitors loved exploring the customized booths and engaging with our content seamlessly.",
    image: "https://via.placeholder.com/150/FFB6C1/000000?text=Sarah",
  },
  {
    name: "Ron Kent",
    role: "Developer",
    feedback:
      "The platform offered the perfect blend of simplicity and interactivity. Our audience was fully immersed, and the ability to explore exhibits in a virtual space added a unique dimension to the experience. It’s a game-changer for online events",
    image: "https://via.placeholder.com/150/87CEFA/000000?text=Ron",
  },
  {
    name: "Rammo Iso",
    role: "Designer",
    feedback:
      "Setting up the exhibition was a breeze, and the response from attendees was fantastic. The platform made it easy to showcase our work while keeping visitors engaged. It’s the perfect solution for hosting virtual events.",
    image: "https://via.placeholder.com/150/FFD700/000000?text=Rammo",
  },
  {
    name: "Alice Doe",
    role: "Marketing Lead",
    feedback:
      "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et magna aliqua. Veniam, quis nostrud ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    image: "https://via.placeholder.com/150/32CD32/000000?text=Alice",
  },
  {
    name: "John Smith",
    role: "CTO",
    feedback:
      "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et magna aliqua. Quis nostrud ullamco laboris nisi ut aliquip ex ea commodo consequat adipiscing dolore.",
    image: "https://via.placeholder.com/150/FF69B4/000000?text=John",
  },
  {
    name: "Emma Brown",
    role: "Product Manager",
    feedback:
      "Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et magna aliqua. Veniam, quis nostrud ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    image: "https://via.placeholder.com/150/1E90FF/000000?text=Emma",
  },
];

const AboutTestimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const testimonialsPerPage = 3; // Number of testimonials to show per page

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  // Handles pagination with fade animation
  const handlePagination = (newIndex) => {
    setFade(false); // Start fade-out
    setTimeout(() => {
      setCurrentIndex(newIndex); // Change testimonial
      setFade(true); // Start fade-in
    }, 500); // Time matches the fade-out transition
  };

  // Handle swipe gestures
  const handleSwipeLeft = () => {
    if (currentIndex < testimonials.length - testimonialsPerPage) {
      handlePagination(currentIndex + testimonialsPerPage);
    }
  };

  const handleSwipeRight = () => {
    if (currentIndex > 0) {
      handlePagination(currentIndex - testimonialsPerPage);
    }
  };

  // Bind swipe handlers to the component
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: true, // Prevent scrolling when swiping
    trackMouse: true, // Enable mouse tracking (for desktop)
  });

  const visibleTestimonials = testimonials.slice(
    currentIndex,
    currentIndex + testimonialsPerPage
  );

  return (
    <section className="px-4 py-4 md:py-28 bg-white ">
      <div className="text-center mb-20 space-y-4 " data-aos="fade-up"> {/* AOS animation for heading */}
        <p className="font-bold text-[16px] font-outfit text-black ">TESTIMONIAL</p>
        <h2 className="text-3xl sm:text-6xl mt-6 font-extrabold font-jakarta text-primary">
          What People Say About Us
        </h2>
      </div>

      {/* Swipeable testimonial cards */}
      <div
        {...swipeHandlers} // Attach swipe handlers to this div
        className={`overflow-hidden transition-opacity duration-200 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
        data-aos="fade-right" // AOS animation for swipeable container
      >
        <div className="flex justify-between flex-col lg:flex-row  items-center mb-10  w-[100%] md:px-24">
          {visibleTestimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col justify-between  lg:w-[30%] h-90  lg:h-[288px]  bg-gray-100 text-sm p-6 shadow-lg rounded-2xl text-start mx-2 flex-shrink-0 mb-4 "
              data-aos="fade-up" // AOS animation for each testimonial
            >
              <p className="mb-6 text-[16px] text-[#58595B] leading-[24px] ">"{testimonial.feedback}"</p>
              <div className="flex justify-start items-center ">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-12 h-12 rounded-full "
                />
                <div className="ml-6 mr-12">
                  <h3 className="font-bold">{testimonial.name}</h3>
                  <p className="text-gray-500">{testimonial.role}</p>
                </div>
                <img src={icon} alt="testimonial icon" className="w-16 h-16" />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Pagination dots */}
      <div className="flex justify-center mt-4" data-aos="fade-up"> {/* AOS animation for pagination */}
        {Array.from({
          length: Math.ceil(testimonials.length / testimonialsPerPage),
        }).map((_, index) => (
          <button
            key={index}
            className={`h-3 w-3 mx-1 rounded-full ${
              currentIndex === index * testimonialsPerPage
                ? "bg-[#191919]"
                : "bg-gray-400"
            }`}
            onClick={() => handlePagination(index * testimonialsPerPage)}
          />
        ))}
      </div>
    </section>
  );
};

export default AboutTestimonial;
