import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Vision from "../images/Vision.svg";
import { Link } from "react-router-dom";
const AboutVision = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  return (
    <section className="w-full md:px-24 px-8 py-24 space-y-4 bg-gray-100">
      <div className="flex justify-center items-start md:items-center flex-col md:flex-row space-y-4 md:space-y-0">
        <div className="md:w-[54%] px-4" data-aos="fade-right"> {/* AOS animation for the image */}
          <img src={Vision} alt="vision" className="w-[100%] h-[100%]" />
        </div>
        <div className="md:w-[46%]  pl-8" data-aos="fade-left"> {/* AOS animation for the text */}
          <h6 className="font-outfit font-bold text-[16px]">VIDEO</h6>
          <h1 className="font-jakarta text-primary text-5xl font-bold mt-4">
            Vision and Values
          </h1>
          <p className="font-outfit text-para text-xl font-normal mb-10 mt-4">
            Our vision is to become the leading platform for immersive virtual
            exhibitions, empowering organizations and creators to engage global
            audiences through innovative, interactive environments. We are
            committed to fostering a future where virtual events are as
            impactful and accessible as in-person experiences, breaking down
            geographical barriers to create meaningful connections.
          </p>
          <div>
          <Link to="/contact-us">
          <button className="bg-primary rounded-3xl px-4 py-3  text-white font-outfit text-sm font-semibold" data-aos="zoom-in"> {/* AOS for button */}
            Get Started
          </button>
          </Link>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default AboutVision;
