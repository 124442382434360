import React from 'react'
import Navbar from '../components/Navbar'
import ContactHero from '../components/ContactHero'
import ContactInfo from '../components/ContactInfo'
import Footer from '../components/Footer'
import Map from '../components/Map'

const ContactUs = () => {
  return (
    <section className='mt-[90px] w-full overflow-x-hidden"'>
   <Navbar />
   <div className="w-full">
      <ContactHero/>
      <ContactInfo/>
      <Map/>
      <Footer/>


    
   </div>



    </section>
  )
}

export default ContactUs