import React from "react";
import Navbar from "../components/Navbar";
import OverlayAbout from "../components/OverlayAbout";
import Mission from "../components/AboutMission";
import Vision from "../components/AboutVision";
import Testimonial from "../components/AboutTestimonial";
import Members from "../components/AboutMembers";
import Footer from "../components/Footer";

const AboutUs = () => {
  return (
    <section className="mt-[90px] w-full overflow-x-hidden"> {/* Prevent horizontal scroll */}
      <Navbar />
      <div className="w-full"> {/* Ensure all components are constrained to full width */}
        <OverlayAbout />
        <Mission />
        <Vision />
        <Testimonial />
        <Members />
      </div>
      <Footer />
    </section>
  );
};

export default AboutUs;
