import React from "react";
import { motion } from "framer-motion"; // Import framer-motion for animations
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Features from "../components/Features";
import WhyAttend from "../components/WhyAttend";
import Results from "../components/Results";
import Countdown from "../components/Countdown";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import WhyChose from "../components/WhyChose";

// Define animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

function App() {
  return (
    <div className="bg-gray-100 w-full overflow-x-hidden">
      <Navbar />
    <div className="w-full">
          {/* Hero Section with fade-in animation */}
          <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }} // Removed once: true to animate every time on scroll
        variants={fadeInUp}
      >
        <Hero />
      </motion.div>

      {/* Features Section with staggered animation */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }} // Removed once: true to animate every time on scroll
        variants={fadeInUp}
      >
        <Features />
      </motion.div>

      {/* Why Attend Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }} // Removed once: true to animate every time on scroll
        variants={fadeInUp}
      >
        <WhyAttend />
      </motion.div>

      {/* Results Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }} // Removed once: true to animate every time on scroll
        variants={fadeInUp}
      >
        <Results />
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }} // Removed once: true to animate every time on scroll
        variants={fadeInUp}
      >
        <WhyChose />
      </motion.div>

      {/* Countdown Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }} // Removed once: true to animate every time on scroll
        variants={fadeInUp}
      >
        <Countdown />
      </motion.div>

      {/* Testimonials Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }} // Removed once: true to animate every time on scroll
        variants={fadeInUp}
      >
        <Testimonials />
      </motion.div>

      <Footer />
    </div>
  
    </div>
  );
}

export default App;
