import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../images/OurSlution.svg";

const OurSolutionHead = () => {
  useEffect(() => {
    AOS.init({ once: false });
  }, []);

  return (
    <section className="w-full mt-[90px]">
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className=""
      >
        <div
          className="flex flex-col justify-center items-center h-[calc(100svh-210px)] space-y-6"
          data-aos="fade-up"
        >
          <h3
            className="text-6xl font-extrabold text-center text-white font-jakarta"
            data-aos="fade-down"
          >
            Our Solution
          </h3>
          <p
            className="text-2xl font-normal text-center text-white font-outfit"
            data-aos="fade-up"
          >
            1st Canadian 2.5D Virtual Exhibition platform
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurSolutionHead;
