import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import box from "../images/box.svg";
import box2 from "../images/people.svg";
import box3 from "../images/frame.svg";

const WhyChose = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: true }); // Initialize AOS
  }, []);

  return (
    <section className="py-16 relative bg-white ">
      <div className="text-center mb-6" data-aos="fade-up">
        <p className="text-black text-sm font-bold">WHY CHOOSE US</p>
      </div>
      <div className=" flex flex-col items-center justify-center  ">
        <h2
          className="text-3xl md:text-6xl font-bold text-center mb-6 mt-[-3px] text-[#2E2460] "
          data-aos="fade-up"
        >
          Why Choose VI Cross?
        </h2>
        <p
          className=" max-w-[70%] text-center justify-center  px-4 sm:px-4 md:px-4 text-[24px] text-[#58595B] mb-20 leading-[33px] "
          data-aos="fade-up"
          data-aos-delay="200"
        >
          We help companies of all sizes host incredible custom events with an
          easy-to-use platform with complimentary project management.
        </p>
      </div>
      <div className="space-y-16 lg:space-y-0 w-full flex flex-col lg:flex-row mx-auto justify-center items-center lg:gap-8 md:gap-20 sm:gap-24 gap-14 mt-8 px-4 sm:px-10 md:px-20">
  {/* First Card */}
  <div className="bg-[#F1F2F6] w-full md:max-w-[70%] lg:max-w-[33%] aspect-[1/1] flex flex-col justify-center items-center text-center rounded-3xl px-6 py-4" data-aos="fade-up" data-aos-delay="300">
    <span className="absolute top-[-15%] text-white mb-6 bg-[#2E2460] w-[28%] h-[28%] rounded-full flex justify-center items-center text-3xl">
      <img src={box} alt="box" className="w-[70%]" />
    </span>
    <h3 className="font-bold text-3xl xl:text-4xl leading-tight max-w-[15ch]">
      Complete Event Tech Suite
    </h3>
    <p className="text-xl xl:text-2xl mt-4 text-[#58595B] px-4 leading-tight max-w-[45ch]">
      Plan, host and manage your events, engage the audience & measure results - all with a single platform.
    </p>
  </div>

  {/* Second Card */}
  <div className="bg-[#F1F2F6] w-full md:max-w-[70%] lg:max-w-[33%] aspect-[1/1] flex flex-col justify-center items-center text-center rounded-3xl px-6 py-4" data-aos="fade-up" data-aos-delay="400">
    <span className="absolute top-[-15%] text-white mb-6 bg-[#2E2460] w-[28%] h-[28%] rounded-full flex justify-center items-center text-3xl">
      <img src={box2} alt="box" className="w-[70%]" />
    </span>
    <h3 className="font-bold text-3xl xl:taxt-4xl leading-tight max-w-[15ch]">
      Inclusive Events For Everyone
    </h3>
    <p className="text-xl xl:text-2xl  mt-4 text-[#58595B] px-4 leading-tight max-w-[45ch]">
      Set up user-friendly, multi-lingual, and WCAG-compliant event websites, accessible for all.
    </p>
  </div>

  {/* Third Card */}
  <div className="bg-[#F1F2F6] w-full md:max-w-[70%] lg:max-w-[33%] aspect-[1/1] flex flex-col justify-center items-center text-center rounded-3xl px-6 py-4" data-aos="fade-up" data-aos-delay="500">
    <span className="absolute top-[-15%] text-white mb-6 bg-[#2E2460] w-[28%] h-[28%] rounded-full flex justify-center items-center text-3xl">
      <img src={box3} alt="box" className="w-[70%]" />
    </span>
    <h3 className="font-bold text-3xl xl:text-4xl leading-tight max-w-[15ch]">
      Done-For-You + Done-By-You
    </h3>
    <p className="text-xl xl:text-2xl mt-4 text-[#58595B] px-4 leading-tight max-w-[45ch]">
      Leverage our fully self-serve backend to set up events yourself or get a dedicated PM to handle it for you.
    </p>
  </div>
</div>

    </section>
  );
};

export default WhyChose;
