import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../images/logo.svg";

const features = [
  {
    title: "Enhance Cross Border Trade",
    description:
      "By providing a dedicated virtual space for Canadian exporters to connect with potential buyers in the Asia-Pacific region.",
  },
  {
    title: "Engaging User Experience",
    description:
      "Through the use of 2.5D graphics, the platform will deliver a visually appealing environment that balances immersion.",
  },
  {
    title: "Facilitate Business Connection",
    description:
      "The platform will include advanced business matching, real-time communication tools, and comprehensive search functions.",
  },
  {
    title: "Multi Devices Support",
    description:
      "By building the platform with React, it will ensure responsiveness and compatibility across various devices and browsers.",
  },
];

const Features = () => {
  useEffect(() => {
    AOS.init({ duration: 600,  once: false, });
  }, []);

  return (
    <section className="p-7 md:p-16 sm:mx-[6px] bg-gray-100">
      <div
        data-aos="fade-up"
        className="bg-white p-3 rounded-3xl shadow-lg "
      >
        <div className="p-4   mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 text-center gap-1">
          {features.map((feature, index) => (
            <div
              key={index}
              data-aos="fade-up"
              className={`p-4 text-start ${
                index !== 0 ? "md:border-l md:border-gray-300 " : ""
              }`}
            >
              <h3 className="text-lg sm:text-2xl font-bold mb-2">
                {feature.title}
              </h3>
              <p className="text-[16px] text-[#707070] ">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div
        data-aos="fade-up"
        className="text-center mb-12 mt-12 space-y-5 "
      >
        <p className="text-lg  font-bold">FEATURES</p>
        <h5 className="text-2xl sm:text-6xl font-bold text-[#2E2460]">
          Core Features
        </h5>
        <p className="px-4 sm:px-24 text-[#58595B] text-[22px] sm:text-[22px] leading-[28px]">
          The Platform Offers Several Core Features To Enhance The User
          Experience And Foster Business Connections. It Provides a 2.5D Virtual
          Space, Creating An Immersive But Accessible Environment For Product
          Showcases And Networking Opportunities.
        </p>
      </div>

      <div data-aos="fade-up" className="flex flex-col md:grid md:grid-cols-5 ">
        <div
          data-aos="fade-right"
          className="flex flex-col space-y-4 justify-center items-start md:col-span-2 md:items-end pr-6"
        >
          <h3 className="text-lg sm:text-[30px] font-bold text-right uppercase">
            2.5D Virtual Environment
          </h3>
          <p className="text-sm sm:text-[22px] sm:text-right text-[#707070] leading-[33px]">
            The platform will utilize 2.5D graphics to create an engaging and
            interactive virtual exhibition space.
          </p>
          <h3 className="text-lg sm:text-[30px] font-bold sm:text-right uppercase">
            Responsive User Interface
          </h3>
          <p className="text-sm sm:text-[22px] sm:text-right text-[#707070] leading-[33px]">
            Developed using React, the platform will offer a dynamic and
            responsive interface that adapts to different screen sizes and
            devices.
          </p>
          <h3 className="text-lg sm:text-[30px] font-bold sm:text-right uppercase">
            Exhibitor Booths
          </h3>
          <p className="text-sm sm:text-[22px] sm:text-right text-[#707070] leading-[33px]">
            Each exhibitor will have a customizable virtual booth where they can
            showcase their products, services, and brand.
          </p>
          <h3 className="text-lg sm:text-[30px] font-bold sm:text-right uppercase">
            Product Search & Filtering
          </h3>
          <p className="text-sm sm:text-[22px] sm:text-right text-[#707070] leading-[33px]">
            Each exhibitor will have a customizable virtual booth where they can
            showcase their products, services, and brand.
          </p>
          <h3 className="text-lg sm:text-[30px] font-bold sm:text-right uppercase">
            Presentation & Webinar Rooms
          </h3>
          <p className="text-sm sm:text-[22px] sm:text-right text-[#707070] leading-[33px]">
            Sellers will have the ability to host live presentations, product
            launches, and webinars within the platform.
          </p>
        </div>

        <div
          data-aos="zoom-in"
          className="flex justify-center my-6  md:col-span-1 md:px-10"
        >
          <img src={logo} alt="Features" className="max-w-s md:max-w-[200px]" />
        </div>

        <div
          data-aos="fade-left"
          className="flex space-y-4 flex-col md:pl-5 md:col-span-2"
        >
          <h3 className="text-lg sm:text-[30px] font-bold text-left uppercase">
            Business Matching & Networking
          </h3>
          <p className="text-sm sm:text-[22px] text-[#707070] leading-[33px]">
            The platform will feature AI-driven business matching services that
            connect buyers with the most relevant exhibitors based on their
            interests and needs.
          </p>
          <h3 className="text-lg sm:text-[30px] font-bold uppercase">
            Content Management System (CMS)
          </h3>
          <p className="text-sm sm:text-[22px] text-[#707070] leading-[33px]">
            An integrated CMS will allow exhibitors and organizers to easily
            manage their content, including updating booth materials, scheduling
            presentations, and managing attendee lists.
          </p>
          <h3 className="text-lg sm:text-[30px] font-bold uppercase">
            Digital Invitations and Engagement Tools
          </h3>
          <p className="text-sm sm:text-[22px] text-[#707070] leading-[33px]">
            The platform will support the creation and distribution of digital
            invitations to potential buyers.
          </p>
          <h3 className="text-lg sm:text-[30px] font-bold uppercase ">
            Analytics and Reporting
          </h3>
          <p className="text-sm sm:text-[22px] text-[#707070] leading-[33px]">
            The platform will include analytics tools that provide exhibitors
            and event organizers with insights into attendee behavior, booth
            visits, engagement levels, and other key metrics.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;
