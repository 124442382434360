import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/navLogo.svg";

const Navbar = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("home");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActiveItem("home");
    } else {
      setActiveItem(path.replace("/", "") || "home");
    }
  }, [location.pathname]);

  const handleSetActive = (item) => {
    setActiveItem(item);
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-white p-4 shadow-md z-10 flex items-center justify-between md:px-20">
      <div className="text-3xl">
        <img src={logo} alt="VI Cross Logo" />
      </div>

      {/* Mobile Menu Button */}
      <button
        className="lg:hidden flex items-center justify-center"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          {isMobileMenuOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          )}
        </svg>
      </button>

      {/* Desktop Menu */}
      <ul className="hidden lg:flex lg:flex-row lg:space-x-10 items-center ml-auto pr-10 text-xl">
        {["home", "about-us", "our-solution", "contact-us"].map((item) => (
          <li key={item} className="relative">
            <Link
              to={item === "home" ? "/" : `/${item}`}
              className={`font-semibold text-[20px] ${
                activeItem === item ? "text-[#2E2460]" : "text-[#1D1D1D]"
              }`}
              onClick={() => handleSetActive(item)}
            >
              {item.replace("-", " ").toUpperCase()}
            </Link>
            {activeItem === item && (
              <div className="absolute left-1/2 transform -translate-x-1/2 w-2 h-2 bg-[#2E2460] rounded-full mt-1" />
            )}
          </li>
        ))}
      </ul>

      {/* Desktop Join Button */}
      <button className="hidden md:block bg-[#2E2460] text-white px-8 py-2 text-xl rounded-3xl font-semibold">
        Join
      </button>

      {/* Mobile Menu */}
      <div
        className={`md:hidden ${
          isMobileMenuOpen ? "block" : "hidden"
        } absolute top-16 left-0 right-0 bg-white shadow-md p-4 transition-all duration-300 my-6`}
      >
        <ul className="flex flex-col space-y-2">
          {["home", "about-us", "our-solution", "contact-us"].map((item) => (
            <li key={item}>
              <Link
                to={item === "home" ? "/" : `/${item}`}
                className={`font-bold ${
                  activeItem === item ? "text-[#2E2460]" : "text-[#1D1D1D]"
                }`}
                onClick={() => handleSetActive(item)}
              >
                {item.replace("-", " ").toUpperCase()}
              </Link>
            </li>
          ))}
        </ul>

        {/* Mobile Join Button */}
        <button className="bg-[#2E2460] text-white px-6 py-2 rounded-3xl w-full mt-4">
          Join
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
