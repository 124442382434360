import React from "react";
import { FaAngleRight } from "react-icons/fa";
import icon from "../images/footer.svg";
import m1 from "../images/m1.svg";
import m2 from "../images/m2.svg";
import m3 from "../images/m3.svg";
import { FaLocationDot } from "react-icons/fa6";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaCcVisa, FaCcMastercard, FaCcApplePay } from "react-icons/fa6";
import {
  FaFacebookF,
  FaInstagramSquare,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#1D1D1D] text-white py-12 px-8 sm:px-16 md:px-20 ">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        <div className="text-center md:text-left">
          <img src={icon} alt="vi cross logo" className="mx-auto md:mx-0 " />
          
        </div>
        <div>
      <h3 className="font-semibold text-xl mb-4">Our Solution</h3>
      <ul className="leading-[35px] text-sm">
      <li  className="flex items-center mb-2">
            <FaAngleRight />
            2.5D Virtual Environment
          </li>
          <li  className="flex items-center mb-2">
            <FaAngleRight />
            Event & Session Management
          </li>
          <li  className="flex items-center mb-2">
            <FaAngleRight />
            ⁠Business Matching & Networking
          </li>
          <li  className="flex items-center mb-2">
            <FaAngleRight />
            Multi Language Support  
          </li>
          <li  className="flex items-center mb-2">
            <FaAngleRight />
            Real Time Analytics
          </li>
        {/* {[
          { name: "Networking People", link: "#" },
          { name: "Conference Planning", link: "#" },
          { name: "Live Webinar", link: "#" },
          // { name: "Contact Us", link: "/contact-us" }, // Anchor link to the Contact Us section
        ].map((service, index) => (
          <li key={index} className="flex items-center mb-2">
            <FaAngleRight />
            <a href={service.link} className="ml-2">
              {service.name}
            </a>
          </li>
        ))}*/}
      </ul> 
    </div>
        <div className="leading-[35px]">
          <h3 className="font-semibold text-xl mb-4">Our Location</h3>
          <div className="flex items-center space-x-3 mb-2 ">
            <FaLocationDot className="text-2xl"/>
            <p>Toronto, Canada</p>
          </div>
          <h3 className="font-semibold text-xl mb-4 mt-6">Support</h3>
          <div className="flex items-center space-x-3">
            <MdMarkEmailUnread className="text-2xl" />
           <a href="mailto:support@vicross.com"> support@vicross.com</a>
          </div>
        </div>
        <div className="md:pl-8  md:mr-2">
          <h3 className="font-semibold text-xl mb-4">Media</h3>
          <div className="space-y-4">
            <div className="flex space-x-4">
              <a href="#">
                <img src={m3} alt="map" className="w-18 h-18" />
              </a>

              <a href="#">
                <img src={m1} alt="map" className="w-18 h-18" />
              </a>
              <a href="#">
                <img src={m3} alt="map" className="w-18 h-18" />
              </a>
            </div>
            <div className="flex space-x-4 mt-2">
              <a href="#">
                <img src={m1} alt="map" className="w-18 h-18" />
              </a>

              <a href="#">
                <img src={m3} alt="map" className="w-18 h-18" />
              </a>
              <a href="#">
                <img src={m1} alt="map" className="w-18 h-18" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-600 h-[0.5px] mt-10 mb-10 mx-10"></div>

      <div className="max-w-6xl  mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 mt-20">
        <div className="cols-span-1">
          <h3 className="font-semibold text-lg mb-8">Follow Us</h3>
          <div className="flex gap-6 text-xl mt-4">
            <FaFacebookF />
            <FaInstagramSquare />
            <FaYoutube />
            <FaTwitter />
          </div>
        </div>
        <div className="relative mb-4 mr-5 col-span-2 px-4 ">
          <h3 className="font-semibold text-xl mb-4 text-center">
            Subscribe Our Newsletter
          </h3>
          <input
            type="email"
            placeholder="Your Email"
            className="pt-2 pl-8 h-[69px] text-white bg-[#60606236] w-full p-3 pr-28 rounded-[40px] focus:outline-none focus:border-blue-500"
          />
          <button className="absolute mt-2 right-[22px]  top-[ 22px] mt-[10px] mr-2 bg-[#60606236]  text-white px-4 md:px-8 py-[10px] rounded-3xl border-2 border-[#453983]">
            Subscribe
          </button>
        </div>
        <div className="flex flex-col md:pl-8 items-start ">
          <h3 className="font-semibold text-xl mb-4">Payment</h3>
          <div className="flex items-center space-x-3 text-3xl mt-4 ">
            <FaCcVisa />
            <FaCcMastercard />
            <FaCcApplePay />
            <FaCcVisa />
            <FaCcMastercard />
          </div>
        </div>
      </div>

      <div className="bg-gray-600 h-[0.5px] mt-20 mx-10"></div>
      <div className="text-center mt-8">
        <p className="text-gray-400 text-xs">
          Copyright © 2024 VI Cross. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
