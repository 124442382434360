import React from 'react'
import map from '../images/map.svg'
const Map = () => {
  return (
    <div>
        <img src={map} alt="map" className="w-[100%] h-[100%]"/>
    </div>
  )
}

export default Map