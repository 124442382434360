import React from "react";
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/AboutUs";
import OurSolution from "./pages/OurSolution";
import ContactUs from "./pages/ContactUs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-solution" element={<OurSolution />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </Router>
  );
};

export default App;
