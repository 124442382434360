import React from "react";
import bg from "../images/heroImage.svg";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <section
      className="mt-[90px] px-11 relative h-screen flex items-center justify-start text-center text-white animate-fadeIn"
      style={{
        backgroundImage: `url(${bg})`, // Use the imported image
        backgroundSize: "cover", // Ensures the image covers the entire section
        backgroundPosition: "center", // Centers the image
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
      }}
    >
      <div className="bg-opacity-50 p-6 sm:p-10 rounded-md w-full lg:w-2/3 flex flex-col items-start gap-0 justify-start text-start animate-slideUp">
        <h1 className="text-3xl sm:text-[60px] font-normal mb-4 sm:mb-6 transition-transform transform hover:scale-105 duration-500 md:leading-[75px]">
          2.5D Web-Based Virtual Exhibition Platform
        </h1>
        <Link to="/contact-us">
        <button className="bg-white mt-4 text-blue-600 font-bold px-4 sm:px-6 py-2 rounded-full text-md sm:text-lg hover:bg-blue-600 hover:text-white transition-all duration-300 ease-in-out transform hover:scale-105">
          Get Started
        </button>
          </Link>
       
      </div>
    </section>
  );
};

export default Hero;
