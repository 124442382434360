import React from 'react'
import Navbar from '../components/Navbar';
import OurSolutionHead from '../components/OurSolutionHead';
import Enhance from '../components/Enhance';
import Benefits from '../components/Benefits';
import Offers from '../components/Offers';
import Countdown from '../components/Countdown';
import Footer from '../components/Footer';
const OurSolution = () => {
  return (
    <section className='w-full  overflow-x-hidden'>
        <Navbar/>
<div className='w-full'>
<OurSolutionHead/>
<Enhance/>
<Benefits/>
<Offers/>
<Countdown/>
<Footer />
</div>
    </section>
   
  )
}

export default OurSolution