import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import S1 from "../images/S1.svg";
import S2 from "../images/S2.svg";
import S3 from "../images/S3.svg";
import S4 from "../images/S4.svg";
import S5 from "../images/S5.svg";
import { Link } from "react-router-dom";
const Offers = () => {
  useEffect(() => {
    AOS.init({
      once: false, // Animation will happen every time you scroll
    });
  }, []);

  return (
    <section className="w-full flex justify-center items-center px-8 md:px-24 py-24 bg-white">
      <div className="flex flex-col lg:flex-row justify-between items-between">
        <div
          className="lg:w-[40%] flex flex-col justify-between items-start"
          data-aos="fade-up"
        >
          <div>
            <h1 className="font-outfit font-bold text-[16px]">FEATURES</h1>
            <h3 className="font-jakarta font-extrabold text-[60px] text-primary">
              Solution We offer
            </h3>
          </div>
          <div className="space-y-10">
            <p className="font-outfit font-semibold text-2xl text-[#151718]">
              We've created an environment that fosters meaningful connections
              and drives business growth.
            </p>
            <div>
            <Link to="/contact-us">
          <button className="bg-primary text-white mt-14 py-[14px] px-10 rounded-[30px] font-outfit font-semibold text-sm">
            Get Started
          </button>
          </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-5 lg:w-[47%]">
          <div
            className="font-outfit font-semibold text-2xl text-[#151718] bg-[#FDFAEF] rounded-3xl flex justify-start items-center py-6 px-10"
            data-aos="fade-right"
          >
            <img src={S1} alt="S1" className="mr-6" /> 2.5D Virtual Environment
          </div>
          <div
            className="font-outfit font-semibold text-2xl text-[#151718] bg-[#EBFAF2] rounded-3xl flex justify-start items-center py-6 px-10"
            data-aos="fade-right"
          >
            <img src={S2} alt="S1" className="mr-6" /> Event & Session Management
          </div>
          <div
            className="font-outfit font-semibold text-2xl text-[#151718] bg-[#EEEEFD] rounded-3xl flex justify-start items-center py-6 pl-10"
            data-aos="fade-right"
          >
            <img src={S3} alt="S1" className="mr-6" />⁠Business Matching & Networking
          </div>
          <div
            className="font-outfit font-semibold text-2xl text-[#151718] bg-[#F4E9FF] rounded-3xl flex justify-start items-center py-6 px-10"
            data-aos="fade-right"
          >
            <img src={S4} alt="S1" className="mr-6" /> Multi Language Support
          </div>
          <div
            className="font-outfit font-semibold text-2xl text-[#151718] bg-[#FFEDED] rounded-3xl flex justify-start items-center py-6 px-10"
            data-aos="fade-right"
          >
            <img src={S5} alt="S1" className="mr-6" /> Real Time Analytics
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offers;
