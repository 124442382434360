import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import EnhanceImg from "../images/Enhance.svg";

const Enhance = () => {
  useEffect(() => {
    AOS.init({ once: false });
  }, []);

  return (
    <section className="w-full flex justify-center items-center px-8 md:px-24">
      <div
        className="bg-[#1D1D1D] flex flex-col md:flex-row justify-center items-center px-14 py-24 rounded-b-[50px] mt-[-5px]"
        data-aos="fade-up"
      >
        <div className="md:w-[65%] space-y-5" data-aos="fade-right">
          <h1 className="text-[40px] font-jakarta font-extrabold text-white">
            Enhance Your Business with Our 2.5D Virtual Exhibition Platform
          </h1>
          <p className="text-xl font-outfit font-normal text-[#FFFFFF]">
            Are you a Canadian supplier looking to expand your reach into the
            dynamic Asia-Pacific market? Our innovative platform bridges the gap
            between Canadian suppliers and Asia-Pacific buyers, offering an
            immersive and engaging experience. With advanced features like
            business matching, real-time communication, and a visually appealing
            2.5D environment, our platform is designed to facilitate cross-border
            trade and drive business growth.
          </p>
        </div>
        <div className="md:w-[35%]" data-aos="fade-left">
          <img src={EnhanceImg} alt="Enhance" className="w-full h-full ml-6" />
        </div>
      </div>
    </section>
  );
};

export default Enhance;
