import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import frame from '../images/Frame1.svg';

const OverlayAbout = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false }); // You can customize the duration, offset, etc.
  }, []);

  return (
    <section className="relative w-full py-12 md:px-40 px-8 space-y-6 bg-gray-100">
      <div
        className="flex flex-col justify-center items-center text-center space-y-4 md:px-20"
        data-aos="fade-up" // Add AOS animation here
      >
        <h1 className="text-8xl font-jakarta font-bold text-primary">About Us</h1>
        <p className="text-2xl font-outfit font-normal text-para">
          We provide a cutting-edge 2.5D virtual exhibition platform that
          combines the immersive qualities of 3D environments with the
          simplicity and accessibility of 2D.
        </p>
      </div>

      <div data-aos="zoom-in"> {/* Add another AOS animation */}
        <img src={frame} alt="frame" className="w-full h-full" />
      </div> 
    </section>
  );
};

export default OverlayAbout;
