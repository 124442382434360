import React from 'react'
import bg from '../images/ContactBg.svg'
const ContactHero = () => {
  return (
    <section className='w-full flex justify-center items-center mt-[90px] py-28'
    style={{
        backgroundImage: `url(${bg})`, // Use the imported image
        backgroundSize: "cover", // Ensures the image covers the entire section
        backgroundPosition: "center", // Centers the image
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
      }}
      > 
   <div>
    <div className=' flex flex-col justify-center items-center   px-4 space-y-4'>
        <h1 className='text-6xl font-jakarta font-bold text-white'>Contact Us</h1>
        <p className='text-2xl font-outfit font-normal text-white text-center'>Have questions or ready to host your virtual exhibition?
      </p>
        <p className='text-2xl font-outfit font-normal text-white PX-32 text-center'>
        Get in touch with us today!</p>
    </div>
   </div>

        
    </section>
    
  )
}

export default ContactHero