import React from "react";

const ContactForm = () => {
  return (
    <div className="bg-white p-8 md:p-12 rounded-3xl shadow-lg max-w-2xl mx-auto ">
      <h2 className="text-2xl md:text-[27px]  font-semibold mb-6 text-gray- font-poppins">
        Fill the Form Below
      </h2>
      <form className="space-y-6">
        {/* Name and Email */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-[16px] font-medium font-outfit ">
              Name
            </label>
            <input
              type="text"
              placeholder="Your Name"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-[16px] font-medium font-outfit">
              Email
            </label>
            <input
              type="email"
              placeholder="Your Email"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            />
          </div>
        </div>

        {/* Phone and Subject */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-[16px] font-medium font-outfit">
              Phone
            </label>
            <input
              type="tel"
              placeholder="Your Phone"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            />
          </div>
          <div>
            <label className="block text-[16px] font-medium font-outfit">
              Subject
            </label>
            <input
              type="text"
              placeholder="Enter Subject"
              className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            />
          </div>
        </div>

        {/* Message */}
        <div>
          <label className="block text-[16px] font-medeium font-outfit">
            Message
          </label>
          <textarea
            placeholder="Enter Your Message"
            className="mt-1 block w-full h-32 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
          />
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className="w-full mx-4 px-6 py-3 bg-primary text-white rounded-md shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
