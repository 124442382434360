import React from "react";
import divider from "../images/divider.svg";
import { FaPhone } from "react-icons/fa6";
import { HiMail } from "react-icons/hi";
import { FaLocationDot } from "react-icons/fa6";
import Form from "../components/ContactForm";

const ContactInfo = () => {
  return (
    <section className="w-full flex justify-start items-center px-4 md:px-24 py-12 bg-gray-100 relative">
      <div className="flex flex-col lg:flex-row md:space-x-14 w-full space-y-8 md:space-y-0 relative">
        {/* Contact Info Section */}
        <div className="flex justify-center items-start flex-col md:w-[45%] w-full space-y-8">
          <div>
          <h3 className="font-outfit font-semibold text-[16px]">Contact Us</h3>
          <h1 className="font-jakarta text-primary text-[32px] md:text-[40px] font-bold">
            Get in Touch
          </h1>
          <img src={divider} alt="divider" className="w-[14%] md:w-[14%] h-[4px] mt-2" />
          <p className="font-outfit text-para text-lg md:text-2xl font-normal mt-3">
            Our team is here to help you create an engaging, immersive
            experience tailored to your needs. Let’s bring your vision to life!
          </p>
          </div>

          {/* Phone Info */}
          <div className="flex items-start  space-x-4 w-full ">
            <div className="text-2xl md:text-3xl bg-primary p-4 rounded-full text-white">
              <FaPhone />
            </div>
            <div className="flex flex-col justify-center mt-[6px]">
              <h1 className="font-outfit font-semibold text-lg md:text-xl">Call Us</h1>
              <p className="font-outfit text-para text-sm md:text-base">
                +1-206-156 2849
              </p>
            </div>
          </div>

          {/* Email Info */}
          <div className="flex items-start space-x-4 w-full">
            <div className="text-2xl md:text-3xl bg-primary p-4 rounded-full text-white">
              <HiMail />
            </div>
            <div className="flex flex-col mt-[6px]">
              <h1 className="font-outfit font-semibold text-lg md:text-xl">Mail Us</h1>
              <p className="font-outfit text-para text-sm md:text-base">
                info@vicross.ca
              </p>
            </div>
          </div>

          {/* Location Info */}
          <div className="flex items-start space-x-4 w-full">
            <div className="text-2xl md:text-3xl bg-primary p-4 rounded-full text-white">
              <FaLocationDot />
            </div>
            <div className="flex flex-col mt-[6px]">
              <h1 className="font-outfit font-semibold text-lg md:text-xl">Visit Us</h1>
              <p className="font-outfit text-para text-sm md:text-base">
                123 Maple Street Toronto, ON M5J 2N8, Canada
              </p>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="relative w-full lg:w-[55%]">
          <div className=" relative lg:absolute top-0 md:top-12 right-0 sm:right-3 md:right-0 w-full md:w-auto">
            <Form />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
