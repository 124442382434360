import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import payment from '../images/Payment.svg';

const AboutMission = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once:false });
  }, []);

  return (
    <section className="w-full md:px-20 px-8 py-20 space-y-4">
      <div
        className="flex justify-center items-start md:items-center flex-col md:flex-row space-y-4 md:space-y-0"
        data-aos="fade-right" // AOS animation for the entire row
      >
        <div className="md:w-[52%] space-y-4" data-aos="fade-up"> {/* AOS animation for the text */}
          <h6 className="font-outfit font-semibold text-[16px]">ABOUT US</h6>
          <h1 className="font-jakarta text-primary text-5xl font-bold">
            Mission Statements
          </h1>
          <p className="font-outfit text-para text-2xl font-normal">
            Our mission is to transform the virtual event experience by
            providing an innovative 2.5D platform that seamlessly blends
            interactivity with accessibility. We aim to empower businesses,
            creators, and organizations to host dynamic virtual exhibitions that
            captivate audiences and foster meaningful engagement.
          </p>
        </div>
        <div className="px-4" data-aos="zoom-in"> {/* AOS animation for the image */}
          <img src={payment} alt="payment" className="w-[90%] h-[90%]" />
        </div>
      </div>
    </section>
  );
};

export default AboutMission;
