import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import attend1 from "../images/whyattend1.svg";
import attend2 from "../images/whyattend2.svg";
import attend3 from "../images/whyattend3.svg";

const WhyAttend = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: false, });
  }, []);

  return (
    <section className="py-16 px-4 bg-[#111111] ">
      <div className="text-center mb-12 space-y-5" data-aos="fade-up ">
        <p className="text-gray-400 text-sm font-bold mb-2">
          WHY JOIN VI CROSS
        </p>
        <h2 className="text-3xl md:text-6xl font-bold text-white">
          Why Attend VI Cross Events
        </h2>
      </div>
      <div className="max-w-[80%] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 px-1 sm:px-14 md:px-6 sm:pt-4">
        <div
          className="text-center relative"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <img src={attend1} alt="Networking" className="mx-auto mb-4 w-[100%]" />
          <button className="font-bold absolute top-[85%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 sm:w-11/12 bg-[#64656599] backdrop-blur-sm text-white py-3 rounded-xl text-xl ">
            Networking Events
          </button>
        </div>
        <div
          className="text-center relative"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <img
            src={attend2}
            alt="Virtual Environment"
            className="mx-auto mb-4 w-[100%]"
          />
          <button className="font-bold absolute top-[85%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 sm:w-11/12 bg-[#64656599] backdrop-blur-sm text-white py-3 rounded-xl text-xl">
            Virtual Environment
          </button>
        </div>
        <div
          className="text-center relative"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <img src={attend3} alt="Live Webinars" className="mx-auto mb-4 w-[100%]" />
          <button className="font-bold absolute top-[85%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 sm:w-11/12 bg-[#64656599] backdrop-blur-sm text-white  py-3 rounded-xl text-xl">
            Live Webinar
          </button>
        </div>
      </div>
    </section>
  );
};

export default WhyAttend;
