import React, { useEffect } from "react";
import AOS from 'aos'; // Import AOS library
import 'aos/dist/aos.css'; // Import AOS styles
import bg from "../images/AboutMember.svg";
import Member1 from "../images/Member1.svg";
import Member2 from "../images/Member2.svg";
import Member3 from "../images/Member3.svg";
import Member4 from "../images/Member4.svg";

const AboutMembers = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  return (
    <section
      className="flex w-full py-24 md:px-24 px-8 bg-gray-100"
      style={{
        backgroundImage: `url(${bg})`, // Use the imported image
        backgroundSize: "cover", // Ensures the image covers the entire section
        backgroundPosition: "center", // Centers the image
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
      }}
    >
      <div className="w-[100%] flex flex-col  space-y-16 justify-center items-center">
        <div className="flex justify-center items-center flex-col space-y-4" data-aos="fade-up"> {/* AOS animation for heading */}
          <h6 className="font-outfit font-semibold text-[16px]">Best Team</h6>
          <h1 className="font-jakarta text-primary text-5xl font-bold">
            Expert Members
          </h1>
        </div>
        <div className="w-[100%] flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-6 justify-center items-center">
          <div className="w-[100%] flex flex-col items-center justify-center space-y-7" data-aos="fade-up"> {/* AOS for each member */}
            <img src={Member1} alt="member1" className="w-[100%] h-[100%]" />
            <p className="font-outfit font-semibold text-2xl">Eugene Wells</p>
          </div>
          <div className="w-[100%] flex flex-col items-center justify-center space-y-7" data-aos="fade-up" data-aos-delay="200"> {/* Adding delay for staggered effect */}
            <img src={Member2} alt="member2" className="w-[100%] h-[100%]" />
            <p className="font-outfit font-semibold text-2xl">Donna Howard</p>
          </div>
          <div className="w-[100%] flex flex-col items-center justify-center space-y-7" data-aos="fade-up" data-aos-delay="400">
            <img src={Member3} alt="member3" className="w-[100%] h-[100%]" />
            <p className="font-outfit font-semibold text-2xl">Donald Henderson</p>
          </div>
          <div className="w-[100%] flex flex-col items-center justify-center space-y-7" data-aos="fade-up" data-aos-delay="600">
            <img src={Member4} alt="member4" className="w-[100%] h-[100%]" />
            <p className="font-outfit font-semibold text-2xl">Elizabeth Walker</p>
          </div>
        </div>

        {/* <button className="bg-primary rounded-[30px] px-9 py-4 text-white font-outfit text-sm font-semibold" data-aos="fade-up">
          View All Team
        </button> */}
      </div>
    </section>
  );
};

export default AboutMembers;
